'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

const TEN_MINUTES_MS = 1000 * 60 * 10
const FORCE_RELOAD_SESSION_STORAGE_KEY = 'force-reload-timestamp'

const getHasReloadedRecently = () => {
  const reloadTimestampString = sessionStorage.getItem(FORCE_RELOAD_SESSION_STORAGE_KEY)
  const reloadTimestamp = reloadTimestampString ? parseInt(reloadTimestampString, 10) : null

  if (!reloadTimestamp) {
    return false
  }

  return Date.now() - reloadTimestamp < TEN_MINUTES_MS
}

type GlobalErrorProps = {
  error: Error & { digest?: string }
  reset: () => void
}

export default function GlobalError({ error }: GlobalErrorProps) {
  const hasReloadedRecently = getHasReloadedRecently()
  const shouldReload = error.name === 'ChunkLoadError' && !hasReloadedRecently

  useEffect(() => {
    Sentry.captureException(error)

    if (shouldReload) {
      sessionStorage.setItem(FORCE_RELOAD_SESSION_STORAGE_KEY, Date.now().toString())
      window.location.reload()
    }
  }, [error, shouldReload])

  if (shouldReload) {
    return (
      <html lang="en">
        <body />
      </html>
    )
  }

  return (
    <html lang="en">
      <body>
        <h1>{'An unexpected error occurred'}</h1>
        <p>{`Details: ${error.message}`}</p>
        {error.digest && <p>{`Digest: ${error.digest}`}</p>}
      </body>
    </html>
  )
}
